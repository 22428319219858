// Fix FOUC on icons
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
  }) => {
    const currentPosition = getSavedScrollPosition(location);
      if (!location.pathname.startsWith('/counselors-programs/') && !location.pathname.startsWith('/resources/'))  {
       window.scrollTo(...(currentPosition || [0, 0]));
     } 
   return false;
    
  };

